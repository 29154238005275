<template>
  <sub-page
      v-model="$store.state.dashboard.pageData"
      :title="$t('Projects')"
      :header-action="openAddProject"
      :header-action-text="$t('AddProject')"
  >


    <ws-data-table
        :items="items"
        :headers="headers"
        class="mt-5"
        :row-action="(item) => $router.push(businessDashLink(`tasks/projects/` + item.uuid , true))"
    >

      <template #item.name="{item}">
        <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
      </template>

    </ws-data-table>

    <template #dialog>
      <ws-dialog
          v-model="displayDialog"
          :title="$t('AddProject')"
          @save="addProject"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

      </ws-dialog>
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "adminCrmProjects",
  data() {
    return {
      displayDialog : false,
      entityData : {},
      items : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' }
      ]
    }
  },
  methods : {

    ...mapActions('adminCrmSystem', [
      'ADD_PROJECT',
      'GET_PROJECTS'
    ]),

    async addProject() {
      let result = await this.ADD_PROJECT(this.entityData)
      if ( !result ) {
        return
      }
      this.items.push(result)
      this.notify(this.$t('ProjectCreated') , 'success')
      this.displayDialog = false
    },
    openAddProject() {
      this.entityData = {}
      this.displayDialog = true
    },
    async initPage() {
      let result = await this.GET_PROJECTS()
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>